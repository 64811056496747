//Authentication is handled here, please refer to auth.js in utils for request logic

import {
	PROJECT_LOADING,
	PROJECT_LOADED,
	PROJECT_LOADED_JOIN,
	SECTION_LOADED,
	UPLOAD_UPDATED,
} from '../utils/consts';

const initialState = {
	loading: null,
	section: null,
	uploadPercent: null,
	serviceLinks: [],
	projectData: {},
	instructionArray: [],
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case PROJECT_LOADED:
		case SECTION_LOADED:
			return {
				...state,
				...payload,
				loading: false,
			};
		case PROJECT_LOADED_JOIN:
			return {
				...state,
				projectData: payload,
				loading: false,
			};
		case PROJECT_LOADING:
			return {
				...state,
				loading: true,
			};
		case UPLOAD_UPDATED:
			return {
				...state,
				hasAlreadyPerformed: true,
				uploadPercent: payload,
			};
		default:
			return state;
	}
}
