import React, { lazy, Suspense, Fragment } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';
import { LoadingGif } from './components/elements/Loading';
import { Provider } from 'react-redux';
import store from './store';
import setAuthToken from './utils/setAuthToken';
import './components/styles/main.css';

const Alerts = lazy(() => import('./components/elements/Alerts'));
const Landing = lazy(() => import('./components/Landing'));

const Home = lazy(() => import('./components/Pages/Home'));
const Join = lazy(() => import('./components/Pages/Join'));
const Score = lazy(() => import('./components/Pages/TrackSelector'));
const Training = lazy(() => import('./components/Pages/Training'));
const RecordingMain = lazy(() => import('./components/Pages/RecorderMain'));
const Login = lazy(() => import('./components/Pages/Login'));

if (localStorage.token) {
	setAuthToken(localStorage.token);
}

const App = () => {
	return (
		<>
			<div className='orientation-portrait'>
				<Provider store={store}>
					<Router>
						<Suspense fallback={LoadingGif}>
							<Fragment>
								<section>
									<Alerts />
									<Switch>
										<Route exact path='/' component={Landing} />
										<Route
											exact
											path='/*'
											component={() => (
												<h5>Please Use QuickJam in Landscape Mode </h5>
											)}
										/>
										<Redirect from='*' to='/' />
									</Switch>
								</section>
							</Fragment>
						</Suspense>
					</Router>
				</Provider>
			</div>
			<div className='orientation-landscape'>
				<Provider store={store}>
					<Router>
						<Suspense fallback={LoadingGif}>
							<Fragment>
								<section>
									<Alerts />
									<Switch>
										<Route exact path='/' component={Landing} />

										<Route exact path='/record/:userId' component={Home} />
										<Route exact path='/join/:projectId' component={Join} />
										<Route exact path='/select/:userId' component={Score} />
										<Route
											exact
											path='/training/:recorderId'
											component={Training}
										/>
										<Route
											exact
											path='/recorder/:userId'
											component={RecordingMain}
										/>
										<Route exact path='/thanks/:userId' component={Home} />
										<Route exact path='/loginNew' component={Login} />
										<Redirect from='*' to='/' />
									</Switch>
								</section>
							</Fragment>
						</Suspense>
					</Router>
				</Provider>
			</div>
		</>
	);
};

export default App;
